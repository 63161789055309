<template>
  <b-card no-body>
    <b-card-header class="pb-50">
      <h5>
        Pencarian
      </h5>
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col
          cols="12"
          md="3"
          class="mb-2"
        >
          <label>No. Penerimaan</label>
          <b-form-input
            id="no_penerimaan"
            v-model="localData.no_penerimaan"
            placeholder="Masukkan nomor penerimaan"
          />
        </b-col>
        <b-col
          cols="12"
          md="3"
          class="mb-2"
        >
          <label>Tanggal Penerimaan</label>
          <flat-pickr
            id="tanggal_penerimaan"
            v-model="localData.tanggal_penerimaan"
            :config="configs.wrap"
            class="form-control"
            style="background-color: #ffff;"
            placeholder="Tanggal Penerimaan"
          />
        </b-col>
        <b-col
          cols="12"
          md="3"
          class="mb-2"
        >
          <label>No Register</label>
          <b-form-input
            id="no_register"
            v-model="localData.no_register"
            placeholder="Masukkan nomor registrasi"
          />
        </b-col>
        <b-col
          cols="12"
          md="3"
          class="mb-2"
        >
          <label>Tanggal Register</label>
          <flat-pickr
            id="tanggal_register"
            v-model="localData.tanggal_register"
            :config="configs.wrap"
            class="form-control"
            style="background-color: #ffff;"
            placeholder="Tanggal Register"
          />
        </b-col>
        <b-col
          cols="12"
          md="3"
          class="mb-2"
        >
          <label>Pelapor</label>
          <b-form-input
            id="pelapor"
            v-model="localData.pelapor"
            placeholder="Masukkan nama pelapor"
          />
        </b-col>
        <b-col
          cols="12"
          md="3"
          class="mb-2"
        >
          <label>Terlapor</label>
          <b-form-input
            id="terlapor"
            v-model="localData.terlapor"
            placeholder="Masukkan nama terlapor"
          />
        </b-col>
        <b-col
          cols="12"
          md="3"
          class="mb-2"
        >
          <label>Perihal</label>
          <b-form-input
            id="perihal"
            v-model="localData.perihal"
            placeholder="Masukkan perihal"
          />
        </b-col>
        <b-col
          cols="12"
          md="3"
          class="mb-2"
        >
          <label>No. Putusan</label>
          <b-form-input
            id="no_putusan"
            v-model="localData.no_putusan"
            placeholder="Masukkan nomor putusan"
          />
        </b-col>
      </b-row>
      <b-row class="d-flex justify-content-end">
        <b-button
              class="mt-2 mr-1 d-flex justify-content-end"
              variant="outline-primary"
              @click="reset()"
            >
              <span class="text-nowrap">Reset</span>
          </b-button>
          <b-button
              class="mt-2 mr-1 d-flex justify-content-end"
              variant="primary"
              @click="loadFilter()"
            >
              <span class="text-nowrap">Cari</span>
          </b-button>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BRow, BCol, BButton, BFormInput,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from "vue-flatpickr-component";
import { Indonesian } from "flatpickr/dist/l10n/id.js";
import 'flatpickr/dist/flatpickr.css';

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    vSelect,
    BButton,
    BFormInput,
    flatPickr,
  },
  data() {
    return {
      localData: {
        no_penerimaan: null,
        tanggal_penerimaan: null,
        no_register: null,
        tanggal_register: null,
        pelapor: null,
        terlapor: null,
        perihal: null,
        no_putusan: null,
      },
      configs: {
        basic: {},
        wrap: {
          wrap: true,
          dateFormat: "d-m-Y",
          locale: Indonesian,
        },
      },
    }
  },
  methods: {
    loadFilter(){
      this.$emit('emitLoadFilter', this.localData)
    },
    reset(){
      this.localData = {
        no_penerimaan: null,
        tanggal_penerimaan: null,
        no_register: null,
        tanggal_register: null,
        pelapor: null,
        terlapor: null,
        perihal: null,
        no_putusan: null,
      }
    },
  },
  props: {
    jenisKerjasamaOptions: {},
    jenisPihakTerlibatOptions: {},
    jenisDokumenOptions: {},
    namaPengusulOptions: {},
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
