<template>
      <div>
        <b-link  title="Lihat Dokumen"  class="me-1 mr-1" v-b-modal.modal_dokumen><feather-icon icon="PaperclipIcon" style="width: 20px; height: 20px;"/></b-link>
          <div>
            <b-modal id="modal_dokumen" title="Dokumen Usulan" hide-footer>
              <b-row>
                <b-col md="12" class="mb-2">
                  <label class="ml-1">Surat Permohonan Usulan<span style="color: red;"> *</span></label>
                  <b-media no-body>
                    <b-media-body class="mt-75 ml-75">
                      <div class="d-flex" style="width: 100%;">
                        <b-button
                          v-if="localData.show_surat_permohonan_usulan"
                          variant="success"
                          size="sm"
                          class="float-right ml-1"
                          @click="lihatData(localData.show_surat_permohonan_usulan)"
                        >
                          Lihat Dokumen
                        </b-button>
                        <h5 v-else>File tidak tersedia!</h5>
                      </div>
                    </b-media-body>
                  </b-media>
                </b-col>
                <b-col md="12" class="mb-2">
                  <label class="ml-1">Draft Usulan</label>
                  <b-media no-body>
                    <b-media-body class="mt-75 ml-75">
                      <div class="d-flex" style="width: 100%;">
                        <b-button
                          v-if="localData.show_draft_usulan"
                          variant="success"
                          size="sm"
                          class="float-right ml-1"
                          @click="lihatData(localData.show_draft_usulan)"
                        >
                          Lihat Dokumen
                        </b-button>
                        <h5 v-else>File tidak tersedia!</h5>
                      </div>
                    </b-media-body>
                  </b-media>
                </b-col>
                <b-col md="12" class="mb-2">
                  <label class="ml-1">Rencana Kerja</label>
                  <b-media no-body>
                    <b-media-body class="mt-75 ml-75">
                      <div class="d-flex" style="width: 100%;">
                        <b-button
                          v-if="localData.show_rencana_kerja"
                          variant="success"
                          size="sm"
                          class="float-right ml-1"
                          @click="lihatData(localData.show_rencana_kerja)"
                        >
                          Lihat Dokumen
                        </b-button>
                        <h5 v-else>File tidak tersedia!</h5>
                      </div>
                    </b-media-body>
                  </b-media>
                </b-col>
              </b-row>
            </b-modal>
          </div>
      </div>
</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import Kerjasama from '@/views/laporan/list/List.vue'
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BFormTextarea,
  BModal,
  BFormFile,
  BMedia,
  BMediaBody,
  BLink,
} from 'bootstrap-vue'
import {
  downloadDokumen,
} from '@/connection-api/laporan'
import { required, email } from '@validations'
import flatPickr from "vue-flatpickr-component";
import { Indonesian } from "flatpickr/dist/l10n/id.js";
import 'flatpickr/dist/flatpickr.css';

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    BFormInvalidFeedback,
    BFormTextarea,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    BButton,
    flatPickr,
    BModal,
    BFormFile,
    BMedia,
    BMediaBody,
    Kerjasama,
    BLink,
  },
  data() {
    return {
      configs: {
        basic: {},
        wrap: {
          wrap: true,
          dateFormat: "d-m-Y",
          locale: Indonesian,
        },
      },
      required,
    }
  },
  props: {
    localData: {},
  },
  methods: {
    async lihatData(file) {
      try {
        const resp = await downloadDokumen(file);
        var type_file = file.substring(file.length - 4);
        if (type_file === ".pdf") {
          this.imageFile = URL.createObjectURL(
            new Blob([resp.data], { type: "application/pdf" })
          );
        } else {
          this.imageFile = URL.createObjectURL(
            new Blob([resp.data], { type: "image/jpeg" })
          );
        }
        window.open(this.imageFile);
      } catch (error) {
        this.makeToast("danger", JSON.stringify("File tidak ditemukan!"));
      }
    },
  }
}
</script>
