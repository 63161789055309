import {
  get, post, update, get2, upload, download, uploadKerjasama
} from './helpers'

export function createDataLaporan($data) {
  return post('/laporan', $data)
}

export function uploadPerihal(file, onUploadProgress) {
  return upload(`/laporan/post-perihal`, file, onUploadProgress)
}

export function downloadPerihal(filename) {
  return download(`/laporan/download-perihal/${filename}`)
}

export function generateNomorPenerimaan($data) {
  return get('/laporan/generate-nomor-penerimaan', $data)
}

export function getDetailDataLaporan(id) {
  return get(`/laporan/${id}`)
}

export function updateDataLaporan($data) {
  return update('/laporan', $data)
}

export function uploadDokumen(id, file, jenisDokumenId, onUploadProgress) {
  return upload(`/laporan/upload-dokumen/${jenisDokumenId}/${id}`, file, onUploadProgress)
}

export function downloadDokumen(filename) {
  return download(`/laporan/download/${filename}`)
}

export function tambahPihak($data) {
  return post(`/laporan/tambah-pihak`, $data)
}

export function hapusPihak(id) {
  return post(`/laporan/hapus-pihak/${id}`)
}

export function deleteLaporan($data) {
  return post(`/laporan/delete`, $data)
}

export function pilihNotaKesepahaman($data) {
  return post(`/laporan/pilih-nota-kesepahaman`, $data)
}

export function pilihInstansi($data) {
  return post(`/laporan/pilih-instansi`, $data)
}

export function pilihUnitUtama($data) {
  return post(`/laporan/pilih-unit-utama`, $data)
}

export function pilihNarahubung($data) {
  return post(`/laporan/pilih-narahubung`, $data)
}

export function pilihPembahasanKerjasama($data) {
  return post(`/laporan/pilih-pembahasan-kerjasama`, $data)
}

export function importDataKerjasama($data) {
  return post(`/laporan/import-data`, $data)
}

export function downloadTemplateImportKerjasama() {
  return download(`/download/template-kerjasama`)
}

export function uploadExcelKerjasama(file, metadata, sumberData, onUploadProgress) {
  return uploadKerjasama('/import/laporan', file, metadata, sumberData, onUploadProgress)
}

export function saveKerjasama($data) {
  return post(`/import/save-kerjasama`, $data)
}

export function uploadDokumenKerjasama(file, kerjasamaId, onUploadProgress) {
  return upload(`/laporan/upload-dokumen/${kerjasamaId}`, file, onUploadProgress)
}

export function perpanjanganKerjasama($data) {
  return post(`/laporan/perpanjangan/usulan-kerjasama`, $data)
}

export function tandaiSelesai($data) {
  return post(`/laporan/perpanjangan/selesai`, $data)
}

export function updateUrl($data) {
  return post(`/laporan/update/url`, $data)
}

export function turnOnOffPrivate($data) {
  return post(`/laporan/update/private`, $data)
}

export function postLaporanTerlapor($data) {
  return post('laporan/laporan-terlapor/update', $data)
}

